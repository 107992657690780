import React from 'react';

const Filter = (props) => (

  <div className="dropdown">
      <div className="btn btn-secondary dropdown-toggle btn-filter" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span className="title">{props.title}</span>
        <img className="bosch-arrow-icon" src="/assets/img/down-arrow.png" alt="Expand to see options" />
      </div>

      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
      {
        props.options.map( (option, i ) => <div className="dropdown-item" key={i} onClick={() => props.setFilters(props.type,option)}>{option}</div> )
      }
      </div>
    </div>
  );

export default Filter;
