import React, { Component } from 'react';
import relays from './data/relays.json';
import Filter from './components/Filter';
// import { types_list,pins_list,voltage_list,ordiode_list, amp_list, function_list } from './data/options';
class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      relays: relays,
      master: relays,
      type:'Relay Type',
      pins:'Number of Pins',
      voltage:'Voltage',
      amperage:'Amperage',
      function:'Function',
      ordiode:'Resistor or Diode',
      pages:[],
      page:0,
      beginIndex: 0,
      endIndex:0,
      types_list: [],
      pins_list:[],
      voltage_list: [],
      ordiode_list: [],
      amp_list: [],
      function_list: [],
    }

  }

componentDidMount(){
  this.setState({relays:this.state.master}, () => this._resetLists());
  this.moveToNext(0);
}

moveToNext = (page) => {
  // console.log('Current Page: ' + page);
  let beginIndex = page * 12;
  let endIndex = Math.min(100,beginIndex + 12);
  this.setState({beginIndex,endIndex,page});
}

getFooter = () => {
  // console.log(this.state.relays.length);
  let ar = [];
  let pages =  Math.floor(this.state.relays.length / 12);
  // console.log('Total Pages:' + pages);
    for(let i=0; i <= pages; i++){
      ar.push(i);
    }
    this.setState({pages:ar}, this.moveToNext(0) );
}

setFilters = (type,value) => {
  //Takes type and value of the drop down calling it and sets the state value, then calls the filter function
  this.setState({[type]:value},() =>  this.filterByDropDown());
}

filterByDropDown(){
  //Resets the relays array and then calls the filtering function steps
  this.setState({relays:this.state.master}, () => this.filterType());
}

filterType(){
  //If the filter value is not the default value, filters and calls the next function, else calls the next function
  if(this.state.type!=='Relay Type'){
    this.setState({relays:this.state.relays.filter( relay  => {
        return relay[0] === this.state.type;
    })}, () => this.filterPins());
  }else{
    this.filterPins();
  }

}

filterPins(){
  if(this.state.pins!=='Number of Pins'){
    this.setState({relays:this.state.relays.filter( relay  => {
        return relay[3] === this.state.pins;
    })},() => this.filterFunction());
  }else{
    this.filterFunction();
  }
}
filterFunction(){
  if(this.state.function!=='Function'){
    this.setState({relays:this.state.relays.filter( relay  => {
        return relay[1] === this.state.function;
    })},() => this.filterVoltage());
  }else{
    this.filterVoltage();
  }
}

filterVoltage(){
  if(this.state.voltage!=='Voltage'){
    this.setState({relays:this.state.relays.filter( relay  => {
        return relay[4] === this.state.voltage;
    })},() => this.filterAmps());
  }else{
    this.filterAmps();
  }
}

filterAmps(){
  if(this.state.amperage!=='Amperage'){
    this.setState({relays:this.state.relays.filter( relay  => {
        return relay[5] === this.state.amperage;
    })},() => this.filterResistor());
  }else{
    this.filterResistor();
  }
}

filterResistor(){
  if(this.state.ordiode!=='Resistor or Diode'){
    this.setState({relays:this.state.relays.filter( relay  => {
        return relay[8] === this.state.ordiode;
    }).filter((relay,i) => i < 12)}, () => this.getFooter());
  }else{
    this.getFooter();
    this._resetLists();
  }
}

_resetLists = () => {
  let types_list = ['Relay Type'];
  let pins_list = ['Number of Pins'];
  let function_list = ['Function'];
  let voltage_list = ['Voltage'];
  let ordiode_list = ['Resistor or Diode'];
  let amp_list = ['Amperage'];

  //Loop over all exisiting filtered records and reset the individual filters.
  this.state.relays.map( info => {

    if(types_list.indexOf(info[0])===-1){
      types_list.push(info[0]);
    }

    if(pins_list.indexOf(info[3])===-1){
      pins_list.push(info[3])
    }
    if(function_list.indexOf(info[1])===-1){
      function_list.push(info[1])
    }
    if(voltage_list.indexOf(info[4])===-1){
      voltage_list.push(info[4])
    }
    if(ordiode_list.indexOf(info[8])===-1){
      ordiode_list.push(info[8])
    }
    if(amp_list.indexOf(info[5])===-1){
      amp_list.push(info[5])
    }
  });

  this.setState({types_list,pins_list,function_list,voltage_list,ordiode_list,amp_list}, () => this.getFooter());
  return;
}

_resetFilters = () => {
  this.setState({ordiode:'Resistor or Diode',type:'Relay Type',pins:'Number of Pins',function:'Function',voltage:'Voltage',amperage:'Amperage',relays:this.state.master},() => this._resetLists())
}

  render(){
  const { beginIndex, endIndex } = this.state;
    return (
      <div className="container-fluid p-2">
        <h1>Relays ({this.state.relays.length})</h1>
        <div className="d-flex flex-column justify-content-lg-center mt-3 mb-4 filter-bar align-items-lg-center">



          <div className="d-lg-flex flex-column ">

            <div className="mr-3 filter-label p-3"> Select your Relay specifications </div>

            <div className="d-lg-flex">
              <Filter title={this.state.type} setFilters={this.setFilters} type='type' options={this.state.types_list} />
              <Filter title={this.state.pins} setFilters={this.setFilters} type='pins' options={this.state.pins_list} />
              <Filter title={this.state.function} setFilters={this.setFilters} type='function' options={this.state.function_list} />
              <Filter title={this.state.voltage} setFilters={this.setFilters} type='voltage' options={this.state.voltage_list} />
              <Filter title={this.state.amperage} setFilters={this.setFilters} type='amperage' options={this.state.amp_list} />
              <Filter title={this.state.ordiode} setFilters={this.setFilters} type='ordiode' options={this.state.ordiode_list} />
              <button className="btn btn-raised ml-3" onClick={() => this._resetFilters()}>Reset</button>
            </div>

          </div>


        </div>

        <div className="row w-100">
          {
            this.state.relays.filter((relay,i) => i >= beginIndex  && i < endIndex).map( (relay, i) => {
              return(
                <div className="col-lg-2 col-md-4 mb-5 text-center" key={i}>
                  <h5>{relay[2]}</h5>
                  <div className="card relays">
                    <div className="card-body d-flex flex-column justify-content-center">
                      <img src={`/assets/img/fuses/${relay[2]}.jpg`} alt={relay[2]} className=" w-100"/>
                    </div>
                  </div>
                </div>
              );
            })
          }

          <div className="col-sm-12 d-flex justify-content-center mb-5">
            {
              (this.state.relays.length) ?
                this.state.pages.map( (p,i) => {
                  return <span key={i} className={`page ${(this.state.page===i) ? 'active' : ''}`} style={{cursor:'pointer',marginRight:'10px'}} onClick={() => this.moveToNext(p)}>{p + 1}</span>
                })
              :
              <p align="center" className="w-100 mt-5">No relays match those selections</p>
            }
          </div>
        </div>
      </div>

    );
  }

}

export default App;
